<div
  class="hero-wrap js-fullheight"
  style="background-image: url('assets/images/new/home/torrebelem.jpg')"
  data-stellar-background-ratio="0.5"
>
  <div class="overlay"></div>
  <div class="container">
    <div
      class="
        row
        no-gutters
        slider-text
        js-fullheight
        align-items-center
        justify-content-center
      "
      data-scrollax-parent="true"
    >
      <div
        class="col-md-9 text text-center ftco-animate"
        data-scrollax=" properties: { translateY: '70%' }"
      >
        <a
          href="https://www.youtube.com/watch?v=z7wyD-0iKnw"
          class="
            icon-video
            popup-vimeo
            d-flex
            align-items-center
            justify-content-center
            mb-4
          "
        >
          <span class="ion-ios-play"></span>
        </a>
        <h1
          id="banner-text"
          data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
        >{{"MsgStart" | tr}}</h1>
        <div class="mt-5">
          <button class="seebtn btn btn-lg btn-primary" (click)="gotoTours()">
            {{ "btnHome1" | tr }}
          </button>
        </div>
      </div>
      <div class="bottom-right-legend text-shadow">
        <a href="https://pixabay.com/pt/users/julius_silver-4371822/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=4876136" target="_blank">Photo by Julius Silver</a>
      </div>
    </div>
  </div>
</div>
<section class="ftco-counter img" id="section-counter">
  <div class="container">
    <div class="container mt-50 mb-50">
      <div class="row d-flex">
        <div class="col-md-6 d-flex">
          <div  class="img d-flex align-self-stretch playbox senhoras"></div>
        </div>
        <div class="col-md-6 pl-md-5">
          <div class="row justify-content-start pb-3">
            <div class="col-md-12 heading-section ftco-animate">
              <h2>{{ "h2Hello" | tr }} </h2>
              <p style="text-align: justify; white-space: pre-wrap">
                {{ "pHello1" | tr}}
              </p>
              <p style="text-align: justify; white-space: pre-wrap">
                {{ "pHello2" | tr}}
                <img
                  src="assets/images/new/smile_small.png"
                  height="25px"
                  alt=""
                />
              </p>
              <p style="text-align: justify; white-space: pre-wrap">
                {{ "pHello3" | tr}}

              </p>
              <p style="text-align: justify; white-space: pre-wrap">
                {{ "pHello4" | tr}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="ftco-section testimony-section bg-bottom">
    <div  class="banner">
      <div class="labelVideo">
        <h2 class="labelVideotxt">{{ "labelVideo" | tr}}</h2>
      </div>
      <video id="vid" autoplay loop muted="muted" class="banner__video" poster="assets/images/new/home/alfama.jpg">
        <source src="assets/video/videoplayback.webpm" type="video/webpm">
        <source src="assets/video/videoplayback.mp4" type="video/mp4">
      </video>
    </div>
</section>

<section class="ftco-counter" id="section-counter-about">
  <div class="container mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="our-team">
            <div class="team-pic">
              <img src="assets/images/new/team/isabel.png"></div>
            <div class="team-profile">
              <h3 class="team-title">
                <a href="javascript:;">Isabel</a>
              </h3>
              <p class="description">I was born in Angola, a former Portuguese colony and I was raised in Lisbon, my
                family’s hometown, where I attended University to earn my degree on Tourism.
                Working as tourist guides has been a tradition in my family, since my grandmother. Indeed, it is my
                passion as well.</p>
              <p class="description" style="cursor: pointer;" (click)="more('Isabel');$event.preventDefault()">
                {{'Tours-More-Title' | tr}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="our-team">
            <div class="team-pic">
              <img src="assets/images/new/team/cristina.png"></div>
            <div class="team-profile team-cristina">
              <h3 class="team-title">
               <a href="javascript:;">Cristina</a>
              </h3>
              <p class="description"> I was born in Lisbon, where I grew up. I took my graduation in the Lisbon Tourism University.
                <Br>I’ve been working with people from different countries, especially USA, Canada, U.K, France, Belgium, Switzerland, Spain and Brazil.
                <Br>I speak fluently Portuguese, English, French, Spanish and I have some knowledge of Italian and Japanese.</p>
              <p class="description" style="cursor: pointer;" (click)="more('Cristina');$event.preventDefault()"> {{'Tours-More-Title' | tr}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="our-team gracaImg" style="background-image: url(assets/images/new/team/graca.png);">
            <div class="team-profile team-graca">
              <h3 class="team-title">
               <a href="javascript:;">Graça</a>
              </h3>
              <p class="description">Since I can remember, I wished to study languages, to enable me to contact with foreigners. After some other professional experiences - teacher and secretary - I finally got the chance of doing what I studied for - to become a licensed tourist guide in Portugal, my country. Many years have passed and it is still (always) a passion!
                <Br>What kind of tours or what nationality do I prefer? Honestly, I cannot say!! One of the good things of my profession is to get to know so many different people and I like to be flexible to adapt myself to the different cultures and needs.</p>
              <p class="description" style="cursor: pointer;" (click)="more('Graca');$event.preventDefault()"> {{'Tours-More-Title' | tr}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section testimony-section bg-bottom" style="background-image: url(assets/images/bg_3.jpg);">
  <div class="container">
    <div class="row justify-content-center pb-4">
      <div class="col-md-7 text-center heading-section ftco-animate">
        <h1 class="mb-4">{{ "labelGuestBook" | tr}}</h1>
      </div>
    </div>
    <div class="row ftco-animate">
      <div class="col-md-12">
        <div class="carousel-testimony owl-carousel ftco-owl">
          <div class="item">
            <div class="testimony-wrap py-4">
              <div class="text">
                <p class="mb-4"><strong>Isabel</strong> is an excellent guide who knows the history of Lisbon and the surrounding area. She makes the area seem magical and beautiful as it is. She has a great sense of humour which shows in all that she does for you as a guide. You will never forget Portugal when you have her as a guide. Her sense of history is unbelievable and she shows great pride in her country.</p>
                <div class="d-flex align-items-center testimonial-person">
                  <div class="pl-3">
                    <p class="name">Lynn Oxley</p>
                    <span class="position">USA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="testimony-wrap py-4">
              <div class="text">
                <p class="mb-4">Dear <strong>Cristina</strong>,
                  Thank you so much for these happy days in Lisbon. It was so enjoyable to be able to travel with you. We have had a wonderful time!<Br/>
                  Wishing you all the best!
                  </p>
                <div class="d-flex align-items-center testimonial-person">
                  <div class="pl-3">
                    <p class="name">Dennis & Louise O’Connell</p>
                    <span class="position">Fresno, California</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div class="text">
                <p class="mb-4">Dear <strong>Grace</strong>,
                  Thank you for helping us discovering Lisbon. Your enthusiasm for all you see makes it very exciting for us.
                  <br/>
                  We would love to come back to Portugal again to see it through your eyes.
                  </p>
                <div class="d-flex align-items-center testimonial-person">
                  <div class="pl-3">
                    <p class="name">Irene & Bill Leaman</p>
                    <span class="position">USA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div class="text">
                <p class="mb-4"><strong>Isabel</strong>, What a fantastic web site! The photographs are so beautiful. Did some of you ladies take them? I loved the video of you four women. It is really great! The other video of Lisboa is a great way to see memories of my trip. </p>
                <div class="d-flex align-items-center testimonial-person">
                  <div class="pl-3">
                    <span class="position">USA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimony-wrap py-4">
              <div class="text">
                <p class="mb-4"><strong>Grace</strong>,
                  Thank you for making our vacation so enjoyable and educational!!!<br/>
                  You are an outstanding tourist guide!
                  </p>
                <div class="d-flex align-items-center testimonial-person">
                  <div class="pl-3">
                    <p class="name">Joe and Pat Rocco</p>
                    <span class="position">USA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


