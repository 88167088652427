<footer class="ftco-footer bg-bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="ftco-footer-widget d-flex">
                    <div class="col-md-3 flex-auto">
                        <img src="../../assets/images/new/footer/4lisbonsmile.svg"/>
                    </div>
                    <div class="col-md-6 flex-auto">
                        <p>{{ "FooterMsg" | tr }}</p>
                    </div>
                </div>
                <div class="ftco-footer-widget ml-4 d-flex">
                    <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                        <!-- <li class="ftco-animate">
                            <a href="https://www.facebook.com/lisbon4smile" target="_blank">
                                <span class="icon-facebook"></span>
                            </a>
                        </li> -->
                        <li class="ftco-animate">
                            <a href="https://www.instagram.com/lisbon4smile" target="_blank">
                                <span class="icon-instagram"></span>
                            </a>
                        </li>
                        <!-- <li class="ftco-animate">
                            <a href="https://www.tripadvisor.com/lisbon4smile" target="_blank">
                                <span class="icon-tripadvisor"></span>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="ftco-footer-widget d-flex">
                    <div class="col-3 flex-auto ">
                        <img src="../../assets/images/new/selos/badge.png" width="116px" height="120px"/>
                    </div>
                    <div class="col-3 flex-auto">
                        <img src="../../assets/images/new/selos/european_safety_seal_logo.png" class="ml-5" width="116px" height="120px"/>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="ftco-footer-widget mb-4 ml-md-7">
                    <h2 class="ftco-heading-2">{{ "FooterContact" | tr }}</h2>
                  <div class="row">
                      <div class="col-12">
                          <span class="icon icon-envelope">&nbsp;&nbsp;</span>
                          <a href="mailto:lisbon4smile@gmail.com">lisbon4smile@gmail.com</a>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                        <span class="icon icon-map-marker">&nbsp;&nbsp;</span>
                        <span class="text" style="white-space: nowrap;">Lisbon - Portugal</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
