import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ModalDialogService } from 'ngx-modal-dialog';
import { MoreModalComponent } from '../more-modal/moreModal.component';
import { TranslatorService } from '../service/translator/translator.service';
import { TourData, TourDataList } from './tour.class';
declare const startOther: any;

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss'],
})
export class ToursComponent implements OnInit {
  constructor(
    private modalDialogService: ModalDialogService,
    private viewContainer: ViewContainerRef,
    private tr: TranslatorService,
  ) {}

  public TourData: any;

  ngOnInit() {
    startOther();
    this.TourData = new TourDataList().TourDataList;
  }


  more(tourNumber: number, title:string, text:string, note:string, withDetails:boolean = false): void {
    this.modalDialogService.openDialog(this.viewContainer, {

      title: this.tr.get(title),
      childComponent: MoreModalComponent,
      actionButtons: [
        {
          text: 'Close',
          buttonClass: 'close theme-icon-close',
        },
      ],
      settings: {
        closeButtonClass: 'close theme-icon-close',
      },
      data: {
        text: this.tr.get(text),
        note: this.tr.get(note),
        withDetails: withDetails,
        typeTour: true,
        images: this.TourData[tourNumber-1].imageArray,
        tour: null
      },
    });
  }
}
