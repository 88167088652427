import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { TranslatorModule } from './service/translator/translator.module';
import { LightboxModule } from 'ngx-lightbox';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy } from '@angular/common';
import { MoreModalComponent } from './more-modal/moreModal.component';
import { LisbonAtGlanceComponent } from './lisbon-at-glance/lisbon-at-glance.component';
import { ToursComponent } from './tours/tours.component';
import { ContactUsComponent } from './contact-us/contact-us.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    MoreModalComponent,
    LisbonAtGlanceComponent,
    ContactUsComponent,
    ToursComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    LightboxModule,
    ModalDialogModule.forRoot(),
    TranslatorModule.forRoot({
      allowedLocales: ["en", "pt", "es", "fr", "it"],
      defaultLocale: "pt"
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt-BR", useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
