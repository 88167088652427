import { Component, OnInit } from '@angular/core';
declare const startOther:any;

@Component({
  selector: 'app-lisbon-at-glance',
  templateUrl: './lisbon-at-glance.component.html',
  styleUrls: ['./lisbon-at-glance.component.scss']
})
export class LisbonAtGlanceComponent implements OnInit {

  constructor() { }

  ngOnInit(){
    startOther();
  };

}
