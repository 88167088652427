<link href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css" rel="stylesheet" />

<nav class="
                navbar navbar-expand-lg navbar-dark
                ftco_navbar
                bg-dark
                ftco-navbar-light
              " id="ftco-navbar">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['home']">
      <span class="logoimg">&nbsp;&nbsp;</span>
    </a>
    <div class="collapse navbar-collapse" id="ftco-nav" [ngClass]="{ 'show': navbarOpen }">
      <div class="row justify-content-md-center">
        <div class="col-12">
          <ul class="navbar-nav ml-auto nowrap">
            <li class="nav-item">
              <a [routerLink]="['home']" class="nav-link">{{
                "NavMenu1" | tr
              }}</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['home']" fragment="about" class="nav-link" (click)="videoload()">{{
                "NavMenu2" | tr
              }}</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['lisbonAtGlance']" class="nav-link">{{
                "NavMenu3" | tr
              }}</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['tours']" class="nav-link">{{
                "NavMenu4" | tr
              }}</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['contact']" class="nav-link">{{
                "NavMenu6" | tr
              }}</a>
            </li>
            <li class="nav-item lang">
              <span (click)="setLanguage('pt')" class="flag-icon flag-icon-pt"></span>
              <span (click)="setLanguage('en')" class="lang-item flag-icon flag-icon-gb"></span>
              <span (click)="setLanguage('fr')" class="lang-item flag-icon flag-icon-fr"></span>
              <span (click)="setLanguage('es')" class="lang-item flag-icon flag-icon-es"></span>
              <span (click)="setLanguage('it')" class="lang-item flag-icon flag-icon-it"></span>
              <br />
              <div class="social-midia">
                <!-- <a href="https://www.facebook.com/lisbon4smile" class="redesocial" target="_blank">
                  <span class="icon-facebook"></span>
                </a> -->
                <a href="https://www.instagram.com/lisbon4smile" class="redesocial" target="_blank">
                  <span class="icon-instagram"></span>
                </a>
                <!-- <a href="https://www.tripadvisor.com/lisbon4smile" class="redesocial" target="_blank">
                  <span class="icon-tripadvisor"></span>
                </a> -->
              </div>
              <div class="selos-ipad">
                <a class="navbar-brand clean" [routerLink]="['home']">
                  <span class="cleanimg">&nbsp;&nbsp;</span>
                </a>
                <a class="navbar-brand europe" [routerLink]="['home']">
                  <span class="cleaneuropeimg">&nbsp;&nbsp;</span>
                </a>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div class="selos-normal">
      <a class="navbar-brand clean" [routerLink]="['home']">
        <span class="cleanimg">&nbsp;&nbsp;</span>
      </a>
      <a class="navbar-brand europe" [routerLink]="['home']">
        <span class="cleaneuropeimg">&nbsp;&nbsp;</span>
      </a>
    </div>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="oi oi-menu"></span>
      Menu
    </button>
    <div class="languages-telemovel">
      <ul class="navbar-nav ml-auto nowrap">
        <li class="nav-item lang">
          <span (click)="setLanguage('pt')" class="flag-icon flag-icon-pt"></span>
          <span (click)="setLanguage('en')" class="lang-item flag-icon flag-icon-gb"></span>
          <span (click)="setLanguage('fr')" class="lang-item flag-icon flag-icon-fr"></span>
          <span (click)="setLanguage('es')" class="lang-item flag-icon flag-icon-es"></span>
          <span (click)="setLanguage('it')" class="lang-item flag-icon flag-icon-it"></span>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- END nav -->
