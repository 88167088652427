<section class="hero-wrap hero-wrap-2 js-fullheight" style="background-image: url('assets/images/new/home/torrebelem.jpg');" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
                <h1 class="mb-3 bread">{{'NavMenu4' | tr}}</h1>
                <p class="breadcrumbs">
                    <span class="mr-2">
                        <a routerlink="">{{'NavMenu1' | tr}}
                            <i class="ion-ios-arrow-forward"></i>
                        </a>
                    </span>
                    <span>{{'NavMenu4' | tr}}
                        <i class="ion-ios-arrow-forward"></i>
                    </span>
                </p>
            </div>
        </div>
    </div>
</section>

<section class="pt-3 services-section bg-light">
  <div class="container">
    <div class="row d-flex">
      <div class="col-md-12 order-md-last heading-section ftco-animate">
        <h2>{{'Tour-H1' | tr}}</h2>
        <h3>{{'Tour-H1-1' | tr}}</h3>
        <p>{{'Tour-Msg' | tr}}
        </p>
      </div>
    </div>
    <div class="row d-flex mt-4" >
      <!-- <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/elevador.jpeg)'" (click)="more(1,'Tour1TitleLabel','Tour1Text',null)"></a>
          <div class="text p-4">
            <span class="price">20€/{{ 'TourPersonLabel' | tr }}</span>
            <h3><a href="#">{{ 'Tour1TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour1TimeTour2' | tr }}</strong></span>
            <Br/>
            <span class="days"><strong>{{ 'Tour1TimeTour' | tr }}</strong></span>
            <div class="row" >
              <div class="col-12">{{ 'Tour1Extra' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" >
              <button class="btn btn-primary" (click)="more(1,'Tour1TitleLabel','Tour1Text',null, true)">+</button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/oldlisbon.png)'" (click)="more(2,'Tour2TitleLabel','Tour2Text',null)"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour2TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour2TimeTour' | tr }}</strong></span>
            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour2Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(2,'Tour2TitleLabel','Tour2Text',null)">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/historicallisbon.png)'" (click)="more(3,'Tour3TitleLabel','Tour3Text',null)"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour3TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour3TimeTour' | tr }}</strong></span>
            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour3Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(3,'Tour3TitleLabel','Tour3Text',null)">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/petiscofoodtour.png)'" (click)="more(4,'Tour4TitleLabel','Tour4Text',null)"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour4TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour4TimeTour' | tr }}</strong></span>
            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour4Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(4,'Tour4TitleLabel','Tour4Text',null)">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/landofnavigators.png)'" (click)="more(5,'Tour5TitleLabel','Tour5Text',null)"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour5TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour5TimeTour' | tr }}</strong></span>
            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour5Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(5,'Tour5TitleLabel','Tour5Text',null)">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/sintra.jpg)'" (click)="more(6,'Tour6TitleLabel','Tour6Text',null)"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour6TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour6TimeTour' | tr }}</strong></span>
            <Br/>
            <span class="days"><strong>{{ 'Tour6TimeTour2' | tr }}</strong></span>
            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour6Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(6,'Tour6TitleLabel','Tour6Text',null)">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="project-wrap">
          <a href="#" onclick="event.preventDefault()" class="img" [style.background-image]="'url(assets/images/new/tour/fullday/full1.png)'" (click)="more(7,'Tour7TitleLabel','Tour7Text', 'Tour7InternalNote')"></a>
          <div class="text p-4">
            <h3><a href="#">{{ 'Tour7TitleLabel' | tr }}</a></h3><span class="days"><strong>{{ 'Tour7TimeTour' | tr }}</strong></span>

            <div class="row">
              <!-- <div class="col-5"><b>{{ 'TourNoteLabel' | tr}}:</b></div> -->
              <div class="col-12">{{ 'Tour7Note' | tr}}</div>
            </div>
            <div class="row align-items-center more mt-3" style="white-space: nowrap;">
              <button class="btn btn-primary" (click)="more(7,'Tour7TitleLabel','Tour7Text', 'Tour7InternalNote')">+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


