<section class="hero-wrap hero-wrap-2 js-fullheight" style="background-image: url('assets/images/new/home/torrebelem.jpg');" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
                <h1 class="mb-3 bread">{{'NavMenu3' | tr}}</h1>
                <p class="breadcrumbs">
                    <span class="mr-2">
                        <a routerlink="">{{'NavMenu1' | tr}}
                            <i class="ion-ios-arrow-forward"></i>
                        </a>
                    </span>
                    <span>{{'NavMenu3' | tr}}
                        <i class="ion-ios-arrow-forward"></i>
                    </span>
                </p>
            </div>
        </div>
    </div>
</section>

<section class="ftco-counter" id="section-counter">
    <div class="container ">
        <div class="container mt-5 mb-5">
            <div class="row d-flex">
                <div class="card">
                    <div class="card_part card_part-1">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                               {{ "lblLisbonAtGlance1" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-2">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance2" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-3">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance3" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-4">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance4" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-5">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance5" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-6">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance6" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-7">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance7" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-8">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance8" | tr}}

                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-9">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance9" | tr}}
                            </div>
                        </div>
                    </div>
                    <div class="card_part card_part-10">
                        <div class="card_part_bg_text">
                            <div class="card_part_text">
                              {{ "lblLisbonAtGlance10" | tr}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex mt-5 mb-5">
                <div id="imgGallery">
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/ALFAMA COM CRUZEIRO.jpeg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/ALFAMA COM S. VICENTE DE FORA.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/ALFAMA COM TORRE DA SÉ.jpeg"/>
                    </div>
                    <div class="tiler ">
                        <img src="assets/images/new/lisbonAtGlance/BAIXA COM CALÇADA PORTUGUESA.jpeg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/BASÍLICA DA ESTRELA ROOFTOP.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/CANTO DA PRAÇA DO COMÉRCIO COM ALFAMA.jpeg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/CASA DOS BICOS E CÉU AZUL.jpeg"/>
                    </div>
                    <div class="tiler ">
                        <img src="assets/images/new/lisbonAtGlance/lisboa tarde.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/PARQUE EDUARDO VII VERDE.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/PARTE BAIXA DE ALFAMA COM CARRIS DE ELÉTRICOS.jpeg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/ALTO DO PARQUE EDUARDO VII vista de Lisboa com Castelo Rui Lima.jpg"/>
                    </div>
                    <div class="tiler ">
                        <img src="assets/images/new/lisbonAtGlance/ESTAÇÃO ROSSIO fachada.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/AMOREIRAS torres.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/JERÓNIMOS geral.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/JERÓNIMOS claustros.jpg"/>
                    </div>
                    <div class="tiler ">
                        <img src="assets/images/new/lisbonAtGlance/HIDROAVIÃO Belém.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/CASTELO S. JORGE outra vista com sunset Rui Lima.jpg"/>
                    </div>
                    <div class="tiler mr-3">
                        <img src="assets/images/new/lisbonAtGlance/CASTELO S. JORGE estátua D. Afonso henriques Rui Lima.jpg"/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
