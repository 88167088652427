<div id="modal-wrapper">
    <div>
        <div class="row" *ngIf="!typeTour">
            <div class="col-md-12 text-center links">
                <div [innerHTML]="txt"></div>
            </div>
        </div>
        <div class="row d-flex" *ngIf="typeTour">
            <div class="card">
                <div *ngFor="let image of images;let i = index">
                    <img class="card_part"  [ngClass]="'card_part-'+(i+1)" [src]="image">
                </div>

                  <!-- <div class="card_part" [ngClass]="card_part" [style.background-image]="'url(../../assets/images/new/tour/landofscape/land6.png)'"></div> -->
                  <!-- <img class="card_part"  src="../../assets/images/new/tour/landofscape/land5.png"> -->
            </div>

            <div class="text-card">
                <div class="project-tab container">
                    <div class="row">
                        <div class="col-md-12">
                            <nav>
                                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">{{"Modal-Details-Title" | tr}}</a>
                                    <a class="nav-item nav-link" id="nav-more-tab" data-toggle="tab" href="#nav-more" role="tab" aria-controls="nav-more" aria-selected="false">{{"Tours-More-Title" | tr}}</a>
                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                                  <div class="text p-4" [innerHTML]="txt"></div>
                                  <div class="row pr-4 pl-4" *ngIf="withDetails" >
                                    <div class="col-5"><b>{{ 'TourGroupLabel' | tr}}:</b></div>
                                    <div class="col-12">{{ 'Tour1GroupSize' | tr }}</div>
                                    <div class="col-5"><b>{{ 'TourDistanceLabel' | tr}}:</b></div>
                                    <div class="col-12">{{ 'Tour1Distance' | tr}}</div>
                                    <div class="col-5"><b>{{ 'TourStartingTimeLabel' | tr}}:</b></div>
                                    <div class="col-12">{{ 'Tour1Time' | tr}}</div>
                                    <div class="col-5"><b>{{ 'TourReservationLabel' | tr}}:</b></div>
                                    <div class="col-12">{{ 'Tour1Required' | tr}}</div>
                                    <div class="col-12">{{ 'Tour1Note' | tr}}</div>
                                    <div class="col-5"><b>{{ 'Tour1PaymentLabel' | tr}}</b></div>
                                    <div class="col-12">{{ 'Tour1Paymenttxt' | tr}}</div>
                                    <div class="col-7"><b>{{ 'Tour1IncludeLabel' | tr}}</b></div>
                                    <div class="col-12">{{ 'Tour1Includetxt' | tr}}</div>
                                  </div>
                                  <div class="text pr-4 pl-4" [innerHTML]="note"></div>
                                </div>
                                <div class="tab-pane fade" id="nav-more" role="tabpanel" aria-labelledby="nav-more-tab">
                                  <div class="text p-4">
                                      <div class="col-12"><a href="mailto:lisbon4smile@gmail.com">{{'emailModalContactUs' | tr}}</a></div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

