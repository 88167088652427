export class TourData {
  id: number;
  imageArray: string[];
}

export class TourDataList {
  TourDataList: TourData[];
  constructor() {
    this.TourDataList = [
      {
        id: 1,
        imageArray: [
                    "../../assets/images/new/tour/walkingtour/walkingtour1.png",
                    "../../assets/images/new/tour/walkingtour/walkingtour2.png",
                    "../../assets/images/new/tour/walkingtour/walkingtour3.png",
                    "../../assets/images/new/tour/walkingtour/walkingtour4.png",
                    "../../assets/images/new/tour/walkingtour/walkingtour5.png",
                    ],
      },
      {
        id: 2,
        imageArray: [
          "../../assets/images/new/tour/oldlisbon/old1.png",
          "../../assets/images/new/tour/oldlisbon/old2.png",
          "../../assets/images/new/tour/oldlisbon/old3.png",
          "../../assets/images/new/tour/oldlisbon/old4.png",
          "../../assets/images/new/tour/oldlisbon/old5.png",
        ],
      },
      {
        id: 3,
        imageArray: [
          "../../assets/images/new/tour/historical/hist1.png",
          "../../assets/images/new/tour/historical/hist2.png",
          "../../assets/images/new/tour/historical/hist3.png",
          "../../assets/images/new/tour/historical/hist4.png",
          "../../assets/images/new/tour/historical/hist5.png",
        ],
      },
      {
        id: 4,
        imageArray: [
          "../../assets/images/new/tour/food/food1.png",
          "../../assets/images/new/tour/food/food2.png",
          "../../assets/images/new/tour/food/food3.png",
          "../../assets/images/new/tour/food/food4.png",
          "../../assets/images/new/tour/food/food5.png",
        ],
      },
      {
        id: 5,
        imageArray: [
          "../../assets/images/new/tour/landofscape/land1.png",
          "../../assets/images/new/tour/landofscape/land2.png",
          "../../assets/images/new/tour/landofscape/land3.png",
          "../../assets/images/new/tour/landofscape/land4.png",
          "../../assets/images/new/tour/landofscape/land5.png",
        ],
      },
      {
        id: 6,
        imageArray: [
          "../../assets/images/new/tour/sintra/sintra2.jpg",
          "../../assets/images/new/tour/sintra/sintra3.jpg",
          "../../assets/images/new/tour/sintra/sintra4.jpg",
          "../../assets/images/new/tour/sintra/sintra5.jpg",
          "../../assets/images/new/tour/sintra/sintra6.jpg",
        ],
      },
      {
        id: 7,
        imageArray: [
          "../../assets/images/new/tour/fullday/full1.png",
          "../../assets/images/new/tour/fullday/full2.png",
          "../../assets/images/new/tour/fullday/full3.png",
          "../../assets/images/new/tour/fullday/full4.png",
          "../../assets/images/new/tour/fullday/full5.png",
        ],
      },
    ];
  }
}
