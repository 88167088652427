import { Component, createPlatform, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from './mustmatch.validator';
declare const startOther:any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  FormData: FormGroup;
  submitted = false;

  constructor(private builder: FormBuilder) { }

  ngOnInit(){
    startOther();
    //this.createForm();
  }

  // onSubmit(){
  //   console.log(this.FormData)
  //   this.submitted = true;
  //   if (this.FormData.invalid) {
  //     return;
  //   }
  //   // display form values on success
  //   alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.FormData.value, null, 4));
  // }

  get f(){return this.FormData.controls}

  // private createForm() {
  //   this.FormData = this.builder.group({
  //     Name: ['',Validators.required],
  //     Phone: ['', Validators.required],
  //     Email: ['', [Validators.compose([Validators.required, Validators.email])]],
  //     Confirmemail: ['', Validators.required],
  //     Questions: ['',Validators.required]
  //   },{
  //     validator: MustMatch('Email','Confirmemail')
  //   })
  // }
}



