import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Component, ComponentRef, ViewEncapsulation} from '@angular/core';
import { TourData } from '../tours/tour.class';


@Component({
  selector: 'more-modal',
  templateUrl: 'moreModal.component.html',
  styleUrls: ['moreModal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreModalComponent implements IModalDialog {
  public txt = "";
  public typeTour:boolean=false;
  public images:string[] = [];
  public tour:TourData;
  public note = "";
  public withDetails = true;
  constructor() {
  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<string>>) {
    this.txt = options.data['text']
    this.typeTour = options.data['typeTour'];
    this.images = options.data['images'];
    this.tour = options.data['tour'];
    this.note =  options.data['note'];
    this.withDetails = options.data['withDetails'];
  }

  getImage(image:string){
    console.log("../../"+image)
    return "../../"+image;
  }

}
