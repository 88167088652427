import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ModalDialogService } from 'ngx-modal-dialog';

import { FormGroup } from '@angular/forms';
import { TranslatorService } from '../service/translator/translator.service';
import { MoreModalComponent } from '../more-modal/moreModal.component';
import { Router } from '@angular/router';
declare const startMain: any;
declare var $: any;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  reserveForm: FormGroup;
  submitted = false;
  pipe = new DatePipe('en-US');

  constructor(
    private tr: TranslatorService,
    private modalDialogService: ModalDialogService,
    private viewContainer: ViewContainerRef,
    private router: Router
  ) {}

  ngOnInit() {
    startMain(this.tr.get('MsgStart'));
  }

  gotoTours():void{
    this.router.navigateByUrl('/tours');
  }

  more(team: string): void {
    let txt = '';
    switch (team) {
      case 'Isabel':
        txt =
          'I work all over the country, although Lisbon and its surroundings are my favorites.\n'  +
          'I’m an expert on walking and food tours (Petiscos Tours) and love to collect traditional recipes.\n'+
          '<br />My major markets are from North America, UK, France, Italy, Spain and Portugal.\n'+
          'I’m fluent in English, French, Italian, Spanish and (of course) Portuguese \n' +
          'My favorite museum in Lisbon is the Glazed Tile Museum (Museu do Azulejo). \n' +
          '<br />Hobbies:  walking, cooking and eating, reading, cultural studies';
        break;
      case 'Cristina':
        txt =
          'My experience is involved not only with Portuguese travel agencies but also international companies,\n' +
          'such as British Airways Holydays, McLaren and foreign Ministers. \n' +
          '<Br>Since 2005 I’ve been recommended by Rick Steves as a local guide in Lisbon and Sintra, mainly for private walking tours. \n' +
          '<Br>Hobbies: Footing, gardening, art and history museums.';
        break;
      case 'Rosa':
        txt =
          'I work with different companies and people from all over the world.\n' +
          '<br>I’m fluent in Portuguese, English, Spanish and I have some knowledge in French.\n' +
          '<br>Hobbies: Reading, visiting cultural sites, sailing, travelling and painting (oil on canvas)';
        break;
      case 'Graca':
        txt =
          'Yet, I dare to share with you one of the compliments I had the chance to receive and which really touched me and \n' +
          "helps me to keep improving: 'Graça, please, never change!!' I hope to have the opportunity of guiding you in our beautiful, \n" +
          'bright capital- Lisbon, as well as in our so very special surroundings. Portugal is a charming country.\n' +
          "<Br>Don't miss it!!!";
        break;

      default:
        break;
    }
    this.modalDialogService.openDialog(this.viewContainer, {
      title: 'More Info About ' + team,
      childComponent: MoreModalComponent,
      settings: {
        closeButtonClass: 'close theme-icon-close',
      },
      data: {
        text: txt,
      },
    });
  }
}
