<section class="hero-wrap hero-wrap-2 js-fullheight" style="background-image: url('assets/images/new/home/torrebelem.jpg');" data-stellar-background-ratio="0.5">
  <div class="overlay"></div>
  <div class="container">
      <div class="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
          <div class="col-md-9 ftco-animate pb-5 text-center">
              <h1 class="mb-3 bread">{{'Contact-Title' | tr}}</h1>
              <p class="breadcrumbs">
                  <span class="mr-2">
                      <a routerlink="">{{'NavMenu1' | tr}}
                          <i class="ion-ios-arrow-forward"></i>
                      </a>
                  </span>
                  <span>{{'NavMenu6' | tr}}
                      <i class="ion-ios-arrow-forward"></i>
                  </span>
              </p>
          </div>
      </div>
  </div>
</section>
<section class="mt-3 contact-section">
  <div class="container">
    <div class="row block-9 justify-content-center">
      <div class="col-md-6  d-flex">
        <div class="contact-mail">
          <h2>{{'Contact-Text'| tr}} <span><a href="mailto:lisbon4smile@gmail.com">lisbon4smile@gmail.com</a></span></h2>
        </div>
        <!-- <form action="mailto:lisbon4smile@gmail.com" method="post" enctype="text/plain" onsubmit="return validateForm()" class="bg-light p-5 contact-form">
          <div class="form-group">
            <input type="text" class="form-control" id="c-name" required placeholder="{{ 'Contact-Label-Name' | tr }}" >
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="c-phone" required placeholder="{{ 'Contact-Label-Phone' | tr }}"  >
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="c-email" required placeholder="{{ 'Contact-Label-Email' | tr }}"  >
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="c-confirmemail" placeholder="{{ 'Contact-Label-Repeat-Email' | tr }}" >
          </div>
          <div class="form-group">
            <textarea cols="30" rows="7" class="form-control" id="c-questions" placeholder="{{ 'Contact-Label-Questions' | tr }}"></textarea>
          </div>
          <div class="form-group text-center">
            <button type="submit" class="btn btn-primary py-3 px-5" >{{ 'Contact-Button-Send' | tr }}</button>
          </div>
        </form> -->

      </div>
    </div>
  </div>
</section>
