import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalDialogService } from 'ngx-modal-dialog';
import { TranslatorService } from '../service/translator/translator.service';
import { filter } from 'rxjs/operators';

declare const require;
declare var $: any;

@Component({
  selector: 'chale-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  navbarOpen = false;
  constructor(
    public tr: TranslatorService,
    private modalDialogService: ModalDialogService,
    private viewContainer: ViewContainerRef,
    private router: Router
    ) { }

  ngOnInit() {
    this.setLanguage('en');
    this.router.events
                .pipe(filter(evt => evt instanceof NavigationEnd))
                .subscribe((evt: NavigationEnd) => {
                  this.navbarOpen = false;
                });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  setLanguage(lang) {
    let translation = require(`../../assets/i18n/${lang}.json`);
    this.tr.setTranslationObject(translation);
    this.tr.setLanguage(lang);
  }

  videoload():void{
    $('#vid')[0].load();
  }
}
